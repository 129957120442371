<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    const url = 'http://127.0.0.1:8789/api/connect'
    this.ajax(url)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  },
  methods: {
    ajax(url) {
      const p = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('POST', url, false)
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
              resolve(JSON.parse(xhr.response))
            } else {
              reject(new Error('Response error'))
            }
          }
        }
        xhr.send(null)
      })
      return p
    }
  }
}
// 屏蔽鼠标右键菜单
document.oncontextmenu = function() {
  return false
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PingFang SC, Hiragino Sans GB, Arial, Microsoft YaHei, Verdana, Roboto, Noto, Helvetica Neue, sans-serif;
}

html,
body,
#app {
  height: 100%;
  width: 100%;
  -ms-user-select: none;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
}

.el-table::before {
  z-index: inherit !important;
}

.el-table .cell {
  line-height: 60px !important;
}

.el-table .el-button {
  font-size: 30px !important;
}

@font-face {
  font-family: element-icons !important;
  src: url('../src/assets/less/fonts/element-icons.woff') format('woff'), url('../src/assets/less/fonts/element-icons.ttf') format('truetype'),
    url('../src/assets/less/fonts/element-icons.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}
</style>
