import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import 'lib-flexible/flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import $api from './service/api.js'
import './assets/css/global.less'

import QRCode from 'qrcode'
Vue.prototype.$QRCode = QRCode

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.directive('focus', {
  inserted: function(el) {
    el.querySelector('input').focus();
  }
});
Vue.prototype.$api = $api;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
