import Vue from 'vue'
import VueRouter from 'vue-router'

//首页
const Home = () => import('../components/Home.vue')
//登录
const Login = () => import('../components/login/Index.vue')
//身份证(诊疗卡号)输入框
const Input = () => import('../components/login/Input.vue')
//就诊人列表
const User = () => import('../components/login/User.vue')
//预约挂号
const Register = () => import('../components/register/Index.vue')
//医生列表
const List = () => import('../components/register/List.vue')
//医生详情
const Info = () => import('../components/register/Info.vue')
//预约挂号下单
const Order = () => import('../components/register/Order.vue')
//门诊缴费
const Outpatient = () => import('../components/outpatient/Index.vue')
//门诊缴费详情
const OutpatientDetail = () => import('../components/outpatient/Detail.vue')
//住院按金
const Inpatient = () => import('../components/inpatient/Index.vue')
//住院清单
const InpatientList = () => import('../components/inpatient/list.vue')
//住院清单详情
const InpatientDetail = () => import('../components/inpatient/detail.vue')
//查看报告
const inspection = () => import('../components/inspection/index.vue')
//查看报告
const inspectiondetails = () => import('../components/inspection/details.vue')
//查看报告
const Report = () => import('../components/report/Index.vue')
//检查报告
const Check = () => import('../components/report/Check.vue')
//体检报告
const peExamin = () => import('../components/peExamin/index.vue')
// /体检报告详列表
const peExaminList = () => import('../components/peExamin/list.vue')
// /体检报告详详情
const peExaminDetail = () => import('../components/peExamin/detail.vue')
//检验报告
const Examine = () => import('../components/report/Examine.vue')
//支付方式
const Mode = () => import('../components/Mode.vue')
//支付状态
const Pay = () => import('../components/Pay.vue')
// 建档绑卡
const File = () => import('../components/file/index.vue')
// 挂号成功
const Succeed = () => import('../components/payResult/succeed.vue')
// 挂号成功
const SucceedOut = () => import('../components/payResult/succeedOut.vue')
// 物价查询
const RegistrationList = () => import('../components/order/index.vue')
// 挂号订单
const RegList = () => import('../components/order/register.vue')
// 缴费订单
const OrderList = () => import('../components/outpatient/order_list.vue')
// 缴费详情
const OrderDetail = () => import('../components/outpatient/order_detail.vue')
// demo
const Demo = () => import('../components/file/demo.vue')
// 扫码
const Code = () => import('../components/login/code.vue')
// 医保支付
const Medical = () => import('../components/outpatient/medical.vue')
// 手机二维码支付
const QrCodePay = () => import('../components/qrCodePay.vue')
const cdCrs = () => import('../components/cdCrs.vue')
const outPaTiePay = () => import('../components/outPaTiePay.vue')
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/home' },
    { path: '/home', component: Home },
    { path: '/login', component: Login },
    { path: '/input', component: Input },
    { path: '/user', component: User },
    { path: '/register', component: Register },
    { path: '/outpatient', component: Outpatient },
    { path: '/inpatient', component: Inpatient },
    { path: '/inpatientList', component: InpatientList },
    { path: '/inpatientDetail', component: InpatientDetail },
    { path: '/report', component: Report },
    { path: '/list', component: List },
    { path: '/info', component: Info },
    { path: '/order', component: Order },
    { path: '/mode', component: Mode },
    { path: '/pay', component: Pay },
    { path: '/check', component: Check },
    { path: '/examine', component: Examine },
    { path: '/file', component: File },
    { path: '/peExamin', component: peExamin },
    { path: '/peExaminList', component: peExaminList },
    { path: '/peExaminDetail', component: peExaminDetail },
    { path: '/succeed', component: Succeed },
    { path: '/succeedOut', component: SucceedOut },
    { path: '/registrationList', component: RegistrationList },
    { path: '/regList', component: RegList },
    { path: '/demo', component: Demo },
    { path: '/outPaTiePay', component: outPaTiePay },
    { path: '/outpatientDetail', component: OutpatientDetail },
    { path: '/medical', component: Medical },
    { path: '/code', component: Code },
    { path: '/orderList', component: OrderList },
    { path: '/orderDetail', component: OrderDetail },
    { path: '/qrCodePay', component: QrCodePay },
    { path: '/cdCrs', component: cdCrs },
    { path: '/inspection', component: inspection },
    { path: '/inspectiondetails', component: inspectiondetails }
  ],
  mode: 'history',
  base: '/'
})
export default router
