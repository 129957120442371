import request from '@/utils/request'

let header = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
}

//患者建档
export function userBindRecordAdd(data) {
  return request({
    url: 'userBindRecord/userBindRecord/add',
    method: 'POST',
    data: data,
    headers: header
  })
}

//查询建档
export function userInfoInHis(data) {
  return request({
    url: '/userBindRecord/userBindRecord/userInfoInHis',
    method: 'POST',
    data: data,
    headers: header
  })
}

// 检验结果列表查询（自助机）
export function getTestResultList(data) {
  return request({
    url: 'yyHis/getTestResultList',
    method: 'POST',
    data: data
  })
}
//收费查询
export function payScanCode(data) {
  return request({
    url: 'scan/pay/payScanCode',
    method: 'POST',
    data: data
  })
}

// 检查报告查询
export function getUserPacsInfo(data) {
  return request({
    url: '/pacs/getUserPacsInfo',
    method: 'POST',
    data: data,
   headers: header
  })
}
// 打印
export function printPacs(data) {
  return request({
    url: '/pacs/printPacs',
    method: 'POST',
    data: data,

  })
}

