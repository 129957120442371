import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.withCredentials = true
const service = axios.create({
  baseURL: 'http://192.168.1.232:8084/wxapp/',
  withCredentials: true,
  crossDomain: true,
  timeout: 60000
})

service.interceptors.request.use(
  config => {
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export default service
