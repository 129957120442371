import Vue from 'vue'
import { Row, Col, Button, Table, TableColumn, Select, Option, DatePicker, Message, Input, Loading } from 'element-ui'

Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Input, Loading)
Vue.use(Loading)

Vue.prototype.$message = Message


